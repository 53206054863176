<template>
  <b-modal
    id="id-popup-quick-input-multiple-pax"
    size="lg"
    centered
    no-close-on-backdrop
    header-bg-variant="light-info"
    footer-class="justify-content-between"
    :title-class="isMobileView ? 'h4 header-class text-dark' : 'h2 header-class text-dark'"
    :title="$t('flight.quickInputPaxInfo')"
    :ok-title="$t('save')"
    :cancel-title="$t('cancel')"
    :ok-disabled="!validInput"
    @show="showHandle"
    @ok="submitHandle"
  >
    <AlertGroupBooking v-if="isCreateGroupBooking" />
    <p
      class="mb-50"
      v-html="$t('flight.quickInputPaxInfoNote')"
    />
    <div class="font-weight-bolder">
      {{ $t('flight.quickInputPaxInfoNoteQuantity') }}
    </div>
    <p
      class="font-weight-bolder ml-md-1"
      :class="isMobileView ? 'font-small-4' : 'font-medium-2'"
    >
      <span class="text-danger">
        {{ maxAdults }}
        {{ $tc('flight.adultPlural', maxAdults) }}

        <template v-if="maxChildren">
          -
          {{ maxChildren }}
          {{ $tc('flight.childPlural', maxChildren) }}
        </template>

        <template v-if="maxInfants">
          -
          {{ maxInfants }}
          {{ $tc('flight.infantdPlural', maxInfants) }}
        </template>
      </span>
    </p>

    <b-form-textarea
      v-model="paxs"
      rows="6"
      class="text-uppercase"
      debounce="300"
    />
    <div
      v-if="errorAlert.length"
      class="font-weight-bolder"
    >
      <BAlert
        show
        variant="danger"
        class="px-2 py-1 my-50 fw-700"
      >
        {{ $t("flight['The number of passengers exceeds the number of seats selected, please check again']") }}
      </BAlert>
      <span class="text-danger">{{ $t("flight['You have entered the following names in excess']") }}:</span>
      <div class="px-1">
        <div
          v-for="(paxError, errorIndex) in errorAlert"
          :key="errorIndex"
          class="text-uppercase"
        >
          <div v-if="errorIndex < 6">
            {{ errorIndex + 1 }}. {{ paxError }}
          </div>
        </div>
        <div v-if="errorAlert.length >= 6">
          ...
        </div>
      </div>
    </div>
    <!-- ANCHOR Nhập nhanh từ PNR -->
    <validation-observer
      #default="{ invalid }"
      ref="refFormObserver"
    >
      <div class="d-flex-center gap-2 my-50">
        <div class="flex-1">
          <b-form-group class="mb-0">
            <validation-provider
              :name="$t('flight.bookingCode')"
              rules="required|min:6|max:6"
            >
              <b-form-input
                v-model="bookingCode"
                :name="$t('flight.bookingCode')"
                maxlength="6"
                :formatter="removeAccentsUpperCaseFormatter"
                :placeholder="$t('flight.quickInputPax.bookingCodePlaceholder')"
                @input="val=> val.toUpperCase()"
              />
            </validation-provider>
          </b-form-group>
        </div>
        <BButton
          variant="outline-primary"
          :disabled="invalid"
          @click="onQuickInputFromBookingCodeHandle"
        >
          Nhập nhanh từ PNR
        </BButton>
      </div>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BAlert, BButton, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import { ref, computed, watch } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { apiReservation } from '@/api'
import { convertISODateTime } from '@/@core/utils/filter'

import { removeAccents, removeAccentsUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import useToast from '@useToast'

export default {
  name: 'ModalQuickInputMultiPax',

  components: {
    BFormTextarea,
    BAlert,
    BButton,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    AlertGroupBooking: () => import('../result/components/AlertGroupBooking.vue'),
  },

  props: {
    maxAdults: {
      type: Number,
      default: 1,
    },
    maxChildren: {
      type: Number,
      default: 0,
    },
    maxInfants: {
      type: Number,
      default: 0,
    },
    isCreateGroupBooking: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { toastSuccess } = useToast()
    const errorAlert = ref([])
    const resultParsePaxs = ref()

    const paxs = ref('')
    const isValid = ref(true)

    const maxPassengers = computed(() => (props.maxAdults + props.maxChildren + props.maxInfants))

    const validInput = computed(() => {
      const cleanedPaxs = removeAccents(paxs.value).replace(/[^a-zA-Z\s\n]/g, '').replace(/ {2,}/g, ' ')
      const lines = cleanedPaxs.split('\n').filter(line => line.trim() !== '').map(line => line.trim())
      const totalPassengers = lines.length

      return totalPassengers > 0
    })

    // const adultHonorifics = ['MR', 'MRS', 'MS', 'O', 'B', 'C', 'ONG', 'BA', 'CO']
    // const childHonorifics = ['MSTR', 'MISS', 'BT', 'BG', 'BE TRAI', 'BE GAI']
    // const infantHonorifics = ['EBT', 'EBG', 'EM BE TRAI', 'EM BE GAI']

    const adultHonorifics = ['MR', 'MRS', 'MS']
    const childHonorifics = ['MSTR', 'MISS']
    const infantHonorifics = []

    const allHonorifics = [
      ...adultHonorifics,
      // childHonorifics is after infantHonorifics because need find 'EM BE TRAI', 'EM BE GAI' then find 'BE TRAI', 'BE GAI'
      ...infantHonorifics,
      ...childHonorifics,
    ]

    // const optionsMR = ['MR', 'O', 'ONG']
    // const optionsMRS = ['MRS', 'B', 'BA']
    // const optionsMS = ['MS', 'C', 'CO']
    // const optionsMSTR = ['MSTR', 'BT', 'EBT', 'BE TRAI', 'EM BE TRAI']
    // const optionsMISS = ['MISS', 'BG', 'EBG', 'BE GAI', 'EM BE GAI']

    const optionsMR = ['MR']
    const optionsMRS = ['MRS']
    const optionsMS = ['MS']
    const optionsMSTR = ['MSTR']
    const optionsMISS = ['MISS']

    function formatBirthday(birthday) {
      const parts = birthday.split('-')
      if (parts[0].length !== 4) {
        const day = parts[0]
        const month = parts[1]
        const year = parts[2]
        birthday = `${year}-${month}-${day}`
      }
      return birthday
    }

    const parsePaxs = () => {
      errorAlert.value = [] // reset error
      // ko để locale = 'en' để tránh đảo ngược tên họ
      const locale = 'vi'
      isValid.value = true

      const parsedResult = {
        ADULT: [],
        CHILD: [],
        INFANT: [],
      }

      const cleanedPaxs = removeAccents(paxs.value).replace(/[^a-zA-Z0-9\s\n/-]/g, '').replace(/ {2,}/g, ' ')
      const lines = cleanedPaxs.split('\n').filter(line => line.trim() !== '').map(line => line.trim()).map(line => line.replace(/^\d+\s/, ''))

      // const modifiedLines = lines.map((line) => line.replace(/^\d+\s/, ''));

      lines.forEach(line => {
        if (parsedResult.ADULT.length + parsedResult.CHILD.length + parsedResult.INFANT.length === maxPassengers.value) {
          errorAlert.value.push(line)
          return // Skip the current iteration if we already have enough passengers of each type
        }

        const cleanedLineFull = removeAccents(line).toUpperCase()

        const splittedLine = cleanedLineFull.split(' ')

        const documentNumber = splittedLine[splittedLine.length - 1]
        const checkDocumentNumber = documentNumber.match(/^\d+$/) ? 'C' : documentNumber.match(/^[a-zA-Z][0-9]+$/) ? 'P' : null
        const cleanedLine = checkDocumentNumber ? splittedLine.slice(0, splittedLine.length - 1).join(' ') : cleanedLineFull

        let honorific = ''
        let fullName = cleanedLine
        let birthday = ''
        let isInfant = false

        // check (INFANT) key word
        if (cleanedLine.includes('INFANT')) {
          isInfant = true
          fullName = cleanedLine.replace('INFANT', '').trim()
        }

        // Check if honorific is at the beginning of the line
        for (const h of allHonorifics) {
          const honorificRegex = new RegExp(`^${h}\\s`)
          const match = cleanedLine.match(honorificRegex)

          if (match) {
            honorific = h
            fullName = cleanedLine.replace(honorificRegex, '')
            break
          }
        }

        // Extract birthday from the line
        const birthdayRegex = /(\d{1,2}[-/]\d{1,2}[-/]\d{4}|\d{4}[-/]\d{2}[-/]\d{2})/ // bd điền ngược => bỏ
        const birthdayMatch = cleanedLine.match(birthdayRegex)

        if (birthdayMatch) {
          const replBirthday = birthdayMatch[1].replace(/\//g, '-')
          birthday = formatBirthday(replBirthday) // Replace slashes with hyphens
          fullName = fullName.replace(birthdayRegex, '').trim()
        }

        // Check if honorific is at the end of the line
        if (!honorific) {
          for (const h of allHonorifics) {
            const honorificRegex = new RegExp(`\\s${h}$`)
            const match = fullName.match(honorificRegex)

            if (match) {
              honorific = h
              fullName = fullName.replace(honorificRegex, '')
              break
            }
          }
        }

        if (!fullName) return

        fullName = fullName.replace(/[-.*+?^${}()|[\]\\]/g, '').trim()

        let firstName = ''
        let lastName = ''
        let words

        // logic for case: LE THAI/DUC => lastName = LE THAI, firstName = DUC
        if (fullName.includes('/')) {
          words = fullName.split('/')
        } else {
          words = fullName.split(' ')
        }
        const wordsCount = words.length

        if (wordsCount === 1) {
          if (locale === 'vi') {
            lastName = words[0]
          } else {
            firstName = words[0].trim()
          }
        } else if (locale === 'vi') {
          firstName = words.slice(1).join(' ').trim()
          lastName = words[0]
        } else {
          firstName = words[0].trim()
          lastName = words.slice(1).join(' ')
        }

        let paxType
        const isValidHonorific = allHonorifics.includes(honorific)

        if (isValidHonorific) {
          if (adultHonorifics.includes(honorific)) {
            if (parsedResult.ADULT.length < props.maxAdults) paxType = 'ADULT'
          } else if (childHonorifics.includes(honorific)) {
            if (parsedResult.CHILD.length < props.maxChildren && !isInfant) {
              paxType = 'CHILD'
            } else if (parsedResult.INFANT.length < props.maxInfants) {
              paxType = 'INFANT'
            }
          } else if (parsedResult.INFANT.length < props.maxInfants) {
            paxType = 'INFANT'
          }
        } else if (parsedResult.ADULT.length < props.maxAdults) {
          paxType = 'ADULT'
        } else if (parsedResult.CHILD.length < props.maxChildren) {
          paxType = 'CHILD'
        } else if (parsedResult.INFANT.length < props.maxInfants) {
          paxType = 'INFANT'
        }

        if (paxType) {
          let title = null

          if (optionsMR.includes(honorific)) {
            title = 'MR'
          } else if (optionsMRS.includes(honorific)) {
            title = 'MRS'
          } else if (optionsMS.includes(honorific)) {
            title = 'MS'
          } else if (optionsMSTR.includes(honorific)) {
            title = 'MSTR'
          } else if (optionsMISS.includes(honorific)) {
            title = 'MISS'
          }

          // clear số trong tên họ
          // 1.1LE/MY MRS
          // 2.1NGUYEN/TRUONG MR
          // 3.1PHAN/NHU MR
          firstName = firstName?.replace(/[0-9]/g, '')
          lastName = lastName?.replace(/[0-9]/g, '')
          fullName = `${lastName} ${firstName}`
          title = title?.replace(/[0-9]/g, '')

          // Document handle
          // const splittedDocumentLine = documentLine?.split(' ')
          // const type = typeDocument === 'C' || typeDocument === 'CCCD'
          //   ? 'C'
          //   : typeDocument === 'P' || typeDocument === 'PP' || typeDocument === 'PASSPORT'
          //     ? 'P' : null
          const document = {
            type: checkDocumentNumber ?? undefined,
            number: checkDocumentNumber ? documentNumber : undefined,
            // nationality: type === 'P' ? splittedDocumentLine[1] : undefined,
            // endDate: type === 'P' ? splittedDocumentLine[2] : undefined,
            // isCountry: type === 'P' ? splittedDocumentLine[3] : undefined,
            // gender: type === 'P' ? (['MR', 'MSTR'].includes(title) ? 'MALE' : ['MRS', 'MS', 'MISS'].includes(title) ? 'FEMALE' : undefined) : undefined,
          }

          parsedResult[paxType].push({
            paxType, title, firstName, lastName, birthday, fullName, document: checkDocumentNumber ? document : undefined,
          })
        }
      })

      // for (const key in parsedResult) {
      //   if (Object.prototype.hasOwnProperty.call(parsedResult, key)) {
      //     const paxs = parsedResult[key]
      //     if (!isEmpty(paxs)) {
      //       for (const pax of paxs) {
      //         if (pax.firstName.match(/\d/) || pax.lastName.match(/\d/)) {
      //           isValid.value = false
      //           const errData = pax.firstName.match(/\d/) || pax.lastName.match(/\d/)

      //           toastError({
      //             title: 'Lỗi định dạng thông tin hành khách',
      //             content: `Vui lòng kiểm tra thông tin dòng số ${paxs.findIndex(pax)}`,
      //           })
      //           console.log('Lỗi: Tên chứa ký tự số', { errData })
      //           return
      //         }
      //       }
      //     }
      //   }
      // }

      // emit('addMultiplePassengers', parsedResult)
      return parsedResult
    }

    watch(paxs, () => {
      resultParsePaxs.value = parsePaxs()
    })

    function submitHandle() {
      emit('addMultiplePassengers', resultParsePaxs.value)
    }

    // ANCHOR quick input from pnr
    const bookingCode = ref()
    async function onQuickInputFromBookingCodeHandle() {
      const res = await apiReservation.getBookingRawsByCode(bookingCode.value)
      if (res) {
        const paxLists = res.data.paxLists.map(pax => {
          const title = pax.firstName.split(' ').includes(pax.title) ? '' : pax.title
          const birthday = pax.birthday.includes('T') ? convertISODateTime(pax.birthday.split('T')[0]).date : pax.birthday
          const isInfant = pax.paxType === 'INFANT'
          const document = pax.document?.find(doc => doc.docType && ['P', 'C'].includes(doc.docType))
          // const documentStringPassport = document && document.docType === 'P' ? `${document.nationality} ${document.endDate} ${document.isCountry}` : ''
          const documentString = document
            ? `${document.number}`
            : ''
          return `${pax.lastName} ${pax.firstName} ${title} ${birthday} ${isInfant ? '(INFANT)' : ''} ${documentString}`.trim().replace(/\s+/g, ' ')
        })
        paxs.value += `${!paxs.value ? '' : '\n'}${paxLists.join('\n')}`
        toastSuccess({
          title: `Đã nhập nhanh tên hành khách từ PNR ${bookingCode.value}`,
        })
      }
    }

    function showHandle() {
      bookingCode.value = null
    }
    return {
      paxs,
      validInput,
      parsePaxs,
      resultParsePaxs,
      submitHandle,
      errorAlert,
      bookingCode,
      showHandle,
      onQuickInputFromBookingCodeHandle,
      removeAccentsUpperCaseFormatter,
    }
  },
}
</script>
